// import { useEffect, useState, forwardRef } from "react";
import { forwardRef } from "react";
// import { getSectionAttribute } from "../../api/services";
// import Spinner from "../../components/spinner/Spinner";

const About = forwardRef(({ sectionId, gradient }, ref) => {
  const data = {
    _id: "663c0252d04a4e71ea80064c",
    title: "Dr. Andrés Acosta Flores",
    description:
      "El Dr. Andrés B. Acosta Flores, es especialista en Traumatología y Ortopedia con subespecialidad en lesiones de Hombro y Rodilla tanto deportivas como degenerativas.\n\nCuenta con una destacada formación académica y profesional. Se graduó con Mención Honorífica de la carrera de Médico Cirujano Partero de la Universidad Autónoma de Nuevo León (UANL) y realizó su especialidad en el Hospital Christus Muguerza avalado por la Universidad de Monterrey (UDEM.\nCompletando posteriormente su formación, cursando el Fellowship de  Cirugía Articular en Hombro y Rodilla avalado por la Universidad Ibero.\nParte de su entrenamiento fue en el Hospital Roger Salengro, en Lille, Francia y en la clínica Alai Sports en Madrid",
    image:
      "https://frontpage-assets.s3.amazonaws.com/acostaflores.com/frontpage/acosta-flores-3.jpeg",
  };
  // const [data, setData] = useState({});
  // const [isLoading, setIsLoading] = useState(true);

  // const fetchData = async () => {
  //   setIsLoading(true);
  //   const response = await getSectionAttribute(sectionId);
  //   setData(response.data);
  //   setIsLoading(false);
  // };

  // useEffect(() => {
  //   if (sectionId) {
  //     fetchData();
  //   }
  // }, [sectionId]);

  // if (isLoading) {
  //   return <Spinner />;
  // }

  return (
    <div
      ref={ref}
      className="relative z-10 mt-32 bg-secondary pb-20 sm:mt-56 sm:pb-24 xl:pb-0"
    >
      <div className="absolute inset-0 overflow-hidden" aria-hidden="true">
        <div className="absolute left-[calc(50%-19rem)] top-[calc(50%-36rem)] transform-gpu blur-3xl">
          <div
            className={`aspect-[1097/1023] w-[68.5625rem] bg-gradient-to-r from-[${gradient.from}] to-[${gradient.to}] opacity-25`}
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
      </div>
      <div className="mx-auto flex max-w-7xl flex-col items-center gap-x-8 gap-y-10 px-6 sm:gap-y-8 lg:px-8 xl:flex-row xl:items-stretch">
        <div className="-mt-8 w-full max-w-2xl xl:-mb-8 xl:w-96 xl:flex-none">
          <div className="relative aspect-[2/1] h-full md:-mx-8 xl:mx-0 xl:aspect-auto">
            <img
              className="absolute inset-0 h-full w-full rounded-2xl bg-gray-800 object-contain sm:object-cover shadow-2xl"
              src={data.image}
              alt=""
            />
          </div>
        </div>
        <div className="w-full max-w-2xl xl:max-w-none xl:flex-auto xl:px-16 xl:py-24">
          <figure className="relative isolate pt-6 sm:pt-12">
            <blockquote className="text-xl font-semibold leading-8 text-white sm:text-xl sm:leading-9">
              <p>{data.description}</p>
            </blockquote>
            <figcaption className="mt-8 text-base">
              <div className="mt-1 text-gray-400">{data.title}</div>
            </figcaption>
          </figure>
        </div>
      </div>
    </div>
  );
});

export default About;
