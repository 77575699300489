import { Routes, Route } from 'react-router-dom';
import FrontPage from '../containers/FrontPage';

const Layout = () => {

  return (
    <Routes>
      <Route path="/" element={<FrontPage/>} />
    </Routes>
  );
};

export default Layout;
