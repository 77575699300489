import { forwardRef } from "react";
// import { useEffect, useState, forwardRef } from "react";
// import { getSectionAttribute } from "../../api/services";
// import Spinner from "../../components/spinner/Spinner";

const Services = forwardRef(({ sectionId }, ref) => {
  const data = {
    services: [
      {
        _id: "663c3e02a5e4630c9858628e",
        description:
          "Evaluación experta y diagnóstico preciso de lesiones y condiciones musculoesqueléticas.",
        image: "",
        title: "Consulta y diagnóstico especializado",
      },
      {
        _id: "667b588d2250fad7c7378bd1",
        description:
          "Terapias no quirúrgicas y tecnologías de vanguardia para promover la curación y la recuperación sin cirugía.",
        image: "",
        title: "Tratamiento conservador avanzado",
      },
      {
        _id: "667b58e92250fad7c7378bd3",
        description:
          "Procedimientos quirúrgicos de alta calidad para corregir lesiones y mejorar la función musculoesquelética.",
        image: "",
        title: "Cirugía ortopédica de precisión",
      },
    ],
  };
  // const [data, setData] = useState({ services: [] });
  // const [isLoading, setIsLoading] = useState(true);

  // const fetchData = async () => {
  //   setIsLoading(true);
  //   const response = await getSectionAttribute(sectionId);
  //   setData(response.data);
  //   setIsLoading(false);
  // };

  // useEffect(() => {
  //   if (sectionId) {
  //     fetchData();
  //   }
  // }, [sectionId]);

  // if (isLoading) {
  //   return <Spinner />;
  // }

  return (
    <div ref={ref} className="bg-white">
      <div className="mx-auto max-w-7xl px-4 py-24 sm:px-6 lg:px-8">
        <div className="rounded-2xl bg-gray-50 px-6 py-16 sm:p-16">
          <div className="mx-auto max-w-xl lg:max-w-none">
            <div className="text-center">
              <h2 className="text-2xl font-bold tracking-tight text-secondary">
                Nuestros servicios
              </h2>
            </div>
            <div className="mx-auto mt-12 grid max-w-sm grid-cols-1 gap-x-8 gap-y-10 sm:max-w-none lg:grid-cols-3">
              {data.services.map((incentive) => (
                <div
                  key={incentive._id}
                  className="text-center sm:flex sm:text-left lg:block lg:text-center"
                >
                  <div className="sm:flex-shrink-0">
                    <div className="flow-root">
                      {incentive.image && (
                        <img
                          className="mx-auto h-16 w-16"
                          src={incentive.image}
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                  <div className="mt-3 sm:ml-6 sm:mt-0 lg:ml-0 lg:mt-6">
                    <h3 className="text-sm font-medium text-secondary">
                      {incentive.title}
                    </h3>
                    <p className="mt-2 text-sm text-gray-500">
                      {incentive.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Services;
