import { forwardRef } from "react";
import { classNames } from "../../helpers/globals";
// import { useEffect, useState, forwardRef } from "react";
// import { getSectionAttribute } from "../../api/services";
// import Spinner from "../../components/spinner/Spinner";
// import { classNames } from "../../helpers/globals";

const Reviews = forwardRef(({ sectionId }, ref) => {
  const data = {
    reviews: [
      {
        _id: "663c42a914c163071714a200",
        title: "C. Sánchez",
        description:
          "Me recomendaron al Dr. Andrés, me cambió mi vida. Su atención me hizo sentir segura y valorada como paciente. Gracias a su tratamiento, mi salud mejoró significativamente, y ahora puedo disfrutar de una vida plena y activa. Su profesionalismo y empatía marcaron una diferencia abismal en mi bienestar.",
        featured: true,
      },
      {
        _id: "663c42af45dbb0e142df7949",
        title: "A. K. Treviño",
        description:
          "Mi cirugía de hombro fue un éxito rotundo. El dr Acosta siempre me explicó todo muy bien. Lo recomiendo ampliamente.",
        featured: false,
      },
      {
        _id: "663c42b72b4f9b7c224fdfc4",
        title: "M. Garza",
        description:
          "La consulta de traumatología fue muy completa, me dio un diagnóstico y tratamiento efectivo que me hizo mejorar sin necesitar cirugía",
        featured: false,
      },
      {
        _id: "663c43212a84c56e40c10977",
        title: "Jorge Ayala",
        description:
          "Después de mi operación de reconstrucción de hombro, he podido retomar mis actividades diarias sin dolor. El cuidado y seguimiento del personal médico fueron excepcionales.",
        featured: false,
      },
      {
        _id: "663c43443710872fa67921ec",
        title: "Fernando Gutierrez",
        description:
          "Mi experiencia con la cirugía de hombro y la rehabilitación posterior fue excelente. Superó mis expectativas en cuanto a atención y resultados.",
        featured: false,
      },
    ],
  };
  // const [data, setData] = useState({ reviews: [] });
  // const [isLoading, setIsLoading] = useState(true);

  // const fetchData = async () => {
  //   setIsLoading(true);
  //   const response = await getSectionAttribute(sectionId);
  //   setData(response.data);
  //   setIsLoading(false);
  // };

  // useEffect(() => {
  //   if (sectionId) {
  //     fetchData();
  //   }
  // }, [sectionId]);

  // if (isLoading) {
  //   return <Spinner />;
  // }

  return (
    <div ref={ref} className="relative isolate bg-white pb-32 pt-24 sm:pt-32">
      <div
        className="absolute inset-x-0 top-1/2 -z-10 -translate-y-1/2 transform-gpu overflow-hidden opacity-30 blur-3xl"
        aria-hidden="true"
      >
        <div
          className="ml-[max(50%,38rem)] aspect-[1313/771] w-[82.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <div
        className="absolute inset-x-0 top-0 -z-10 flex transform-gpu overflow-hidden pt-32 opacity-25 blur-3xl sm:pt-40 xl:justify-end"
        aria-hidden="true"
      >
        <div
          className="ml-[-22rem] aspect-[1313/771] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] xl:ml-0 xl:mr-[calc(50%-12rem)]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-xl text-center">
          <h2 className="text-lg font-semibold leading-8 tracking-tight text-indigo-600">
            Reseñas
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Testimonios de nuestros pacientes
          </p>
        </div>
        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 grid-rows-1 gap-8 text-sm leading-6 text-gray-900 sm:mt-20 sm:grid-cols-2 xl:mx-0 xl:max-w-none xl:grid-flow-col xl:grid-cols-4">
          <figure className="rounded-2xl bg-white shadow-lg ring-1 ring-gray-900/5 sm:col-span-2 xl:col-start-2 xl:row-end-1">
            <blockquote className="p-6 text-lg font-semibold leading-7 tracking-tight text-gray-900 sm:p-12 sm:text-xl sm:leading-8">
              <p>{`“${
                data.reviews.filter((e) => e.featured)?.[0]?.description
              }”`}</p>
            </blockquote>
            <figcaption className="flex flex-wrap items-center gap-x-4 gap-y-4 border-t border-gray-900/10 px-6 py-4 sm:flex-nowrap">
              {/* {featured.author.imageUrl && <img
                className="h-10 w-10 flex-none rounded-full bg-gray-50"
                src={featured.author.imageUrl}
                alt=""
              />} */}
              <div className="flex-auto">
                <div className="font-semibold">
                  {data.reviews.filter((e) => e.featured)?.[0]?.title}
                </div>
                {/* {featured.author.handle &&<div className="text-gray-600">{`@${featured.author.handle}`}</div>} */}
              </div>
              {/* <img className="h-10 w-auto flex-none" src={featured.author.logoUrl} alt="" /> */}
            </figcaption>
          </figure>
          {data.reviews
            .filter((e) => !e.featured)
            .map((review, idx) => (
              <div
                key={`te-${review.id}`}
                className="space-y-8 xl:contents xl:space-y-0"
              >
                <div
                  key={`ts-${review.id}`}
                  className={classNames(
                    idx === 0 ||
                      idx === data.reviews.filter((e) => !e.featured).length - 1
                      ? "xl:row-span-2"
                      : "xl:row-start-1",
                    "space-y-8"
                  )}
                >
                  <figure
                    key={`re-${Math.ceil(Math.random(3) * 100)}`}
                    className="rounded-2xl bg-white p-6 shadow-lg ring-1 ring-gray-900/5"
                  >
                    <blockquote className="text-gray-900">
                      <p>{`“${review.description}”`}</p>
                    </blockquote>
                    <figcaption className="mt-6 flex items-center gap-x-4">
                      {/* {review.author.imageUrl && <img className="h-10 w-10 rounded-full bg-gray-50" src={review.author.imageUrl} alt="" />} */}
                      <div>
                        <div className="font-semibold">{review.title}</div>
                        {/* {review.author.handle &&<div className="text-gray-600">{`@${review.author.handle}`}</div>} */}
                      </div>
                    </figcaption>
                  </figure>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
});

export default Reviews;
