import { forwardRef } from "react";
// import { useEffect, useState, forwardRef } from "react";
// import { getSectionAttribute } from "../../api/services";
// import Spinner from "../../components/spinner/Spinner";
// import { _contacts } from "../../mocks/contact";

const Contact = forwardRef(({ sectionId }, ref) => {
  const data = {
    location: {
      title: "Ubicación",
      description: "Disponible en Monterrey, N.L., México",
      items: [
        {
          _id: "663c4a0f932d972cf099cc64",
          name: "Hospital Muguerza Alta Especialidad, Consultorio 205",
          street: "Miguel Hidalgo y Costilla 2525",
          location: "Obispado",
          city: "Monterrey",
          state: "NL",
          zipCode: "64060",
        },
      ],
    },
    contact: {
      title: "Datos de contacto",
      description: "Un punto de contacto según tu necesidad.",
      items: [
        {
          _id: "663c49afa43c616c8a428976",
          name: "Consultas",
          email: "dr.andresbacosta@gmail.com",
          phone: "818-347-4799",
        },
        {
          _id: "663c49bffb8e42044c04486f",
          name: "Cirugías",
          email: "dr.andresbacosta@gmail.com",
          phone: "818-333-6344",
        },
      ],
    },
  };
  // const [data, setData] = useState(_contacts);
  // const [isLoading, setIsLoading] = useState(true);

  // const fetchData = async () => {
  //   setIsLoading(true);
  //   const response = await getSectionAttribute(sectionId);
  //   setData(response.data);
  //   setIsLoading(false);
  // };

  // useEffect(() => {
  //   if (sectionId) {
  //     fetchData();
  //   }
  // }, [sectionId]);

  // if (isLoading) {
  //   return <Spinner />;
  // }

  return (
    <div ref={ref} className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl space-y-16 divide-y divide-gray-100 lg:mx-0 lg:max-w-none">
          <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-3">
            <div>
              <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                {data.contact.title}
              </h2>
              <p className="mt-4 leading-7 text-gray-600">
                {data.contact.description}
              </p>
            </div>
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:col-span-2 lg:gap-8">
              {data.contact.items.map((c) => (
                <div key={c._id} className="rounded-2xl bg-gray-50 p-10">
                  <h3 className="text-base font-semibold leading-7 text-gray-900">
                    {c.name}
                  </h3>
                  <dl className="mt-3 space-y-1 text-sm leading-6 text-gray-600">
                    <div>
                      <dt className="sr-only">Correo electrónico</dt>
                      <dd>
                        <a
                          className="font-semibold text-indigo-600"
                          href="mailto:cirugias@acostaflores.com"
                        >
                          {c.email}
                        </a>
                      </dd>
                    </div>
                    <div className="mt-1">
                      <dt className="sr-only">Teléfono</dt>
                      <dd>{c.phone}</dd>
                    </div>
                  </dl>
                </div>
              ))}
            </div>
          </div>
          <div className="grid grid-cols-1 gap-x-8 gap-y-10 pt-16 lg:grid-cols-3">
            <div>
              <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                {data.location.title}
              </h2>
              <p className="mt-4 leading-7 text-gray-600">
                {data.location.description}
              </p>
            </div>
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:col-span-2 lg:gap-8">
              {data.location.items.map((l) => (
                <div key={l._id} className="rounded-2xl bg-gray-50 p-10">
                  <h3 className="text-base font-semibold leading-7 text-gray-900">
                    {l.name}
                  </h3>
                  <address className="mt-3 space-y-1 text-sm not-italic leading-6 text-gray-600">
                    <p>{l.street}</p>
                    <p>
                      {l.location}, {l.city}, {l.state}. {l.zipCode}
                    </p>
                  </address>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Contact;
