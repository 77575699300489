import { useEffect, useRef, useState } from "react";
import { getUrlFromIframeSnippet } from "../../utils/helpers";

const BookAppointment = ({ iframeUrl }) => {
  const ref = useRef(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    if (showModal) {
      ref.current.showModal();
    } else {
      ref.current.close();
    }
  }, [showModal]);

  return (
    <>
      <button
        className="rounded-md bg-primary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
        onClick={() => setShowModal((prev) => !prev)}
      >
        Agendar cita
      </button>
      <dialog
        ref={ref}
        onClick={(e) => {
          if (!ref.current) {
            return;
          }

          const dialogDimensions = ref.current.getBoundingClientRect();
          if (
            e.clientX < dialogDimensions.left ||
            e.clientX > dialogDimensions.right ||
            e.clientY < dialogDimensions.top ||
            e.clientY > dialogDimensions.bottom
          ) {
            ref.current.close();
            setShowModal(false);
          }
        }}
        className="h-full md:h-5/6 w-full md:w-1/2 rounded-lg shadow-md"
      >
        <iframe
          src={getUrlFromIframeSnippet(iframeUrl)}
          style={{ border: 0 }}
          className="w-full h-full"
          frameborder="0"
        ></iframe>
      </dialog>
    </>
  );
};

export default BookAppointment;
