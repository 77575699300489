export const info = {
  name: '',
  bio: '',
  image: '',
  title: ''
}

export const gradient = {
  from: '#8072fe',
  to: '#91d7f4'
}