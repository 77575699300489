import { useEffect, useRef, useState } from "react";
import About from "../components/about/About";
import Footer from "../components/footers/Footer";
import Header from "../components/headers/Header";
import HeroSection from "../components/hero-sections/HeroSection";
import Services from "../components/services/Services";
import Reviews from "../components/reviews/Reviews";
import Contact from "../components/contact/Contact";
import { navigation, social } from "../mocks/menu/settings";
import { gradient } from "../mocks/about/settings";
import { metaTags } from "../mocks/meta/tags";
import usePageSEO from "../hooks/usePageSEO";
import { changeTheme } from "../utils/globals";
// import { changeTheme, getPort } from "../utils/globals";
// import { getWebsite } from "../api/services";

const FrontPage = () => {
  usePageSEO({
    title: metaTags.title,
    description: metaTags.desc,
    keyworkds: metaTags.keyworkds,
    ogTitle: metaTags.title,
    ogDescription: metaTags.desc,
    ogImage: metaTags.image,
    ogUrl: metaTags.url,
  });
  const website = null;
  const heroId = "";
  const aboutId = "";
  const reviewId = "";
  const serviceId = "";
  const contactId = "";
  // const domain = "";
  // const [website, setWebsite] = useState(null);
  // const [heroId, setHeroId] = useState("");
  // const [aboutId, setAboutId] = useState("");
  // const [reviewId, setReviewId] = useState("");
  // const [serviceId, setServiceId] = useState("");
  // const [contactId, setContactId] = useState("");
  const [font, setFont] = useState(null);
  // const [domain, setDomain] = useState("");
  // const [whatsapp, setWhatsapp] = useState("");

  const header = { navigation };
  const footer = { navigation, social };
  const about = { gradient };

  const sectionsRef = {
    hero: useRef(null),
    about: useRef(null),
    services: useRef(null),
    reviews: useRef(null),
    contact: useRef(null),
  };

  const scrollToSection = (section) => {
    sectionsRef[section].current.scrollIntoView({ behavior: "smooth" });
  };

  // const setTheme = (theme) => {
  //   if (theme?.primaryColor)
  //     changeTheme(theme?.primaryColor, theme?.secondaryColor);
  //   if (theme?.fontFamily) setFont(theme?.fontFamily);
  // };

  const setTheme = (theme) => {
    changeTheme("#8173FF", "#010030");
    setFont("font-grotesk");
  };

  // useEffect(() => {
  //   const port = getPort();
  //   const protocol = window.location.protocol;
  //   const hostname = window.location.hostname;
  //   const currentDomain = `${protocol}//${hostname}${port}`;
  //   setDomain(currentDomain);
  // }, []);

  // const setSections = (sections) => {
  //   setHeroId(sections[0]?.sectionId);
  //   setAboutId(sections[1]?.sectionId);
  //   setServiceId(sections[2]?.sectionId);
  //   setReviewId(sections[3]?.sectionId);
  //   setContactId(sections[4]?.sectionId);
  // };

  // const fetchWebsite = async () => {
  //   if (domain) {
  //     const response = await getWebsite(domain);
  //     setSections(response.sections);
  //     setWebsite(response);
  //     setTheme(response);
  //   }
  // };

  // useEffect(() => {
  //   fetchWebsite();
  // }, [domain]);

  useEffect(() => {
    setTheme();
  }, []);

  return (
    <div className={`bg-white ${font}`}>
      <>
        <Header {...header} callback={scrollToSection} />
        <HeroSection
          sectionId={heroId}
          ref={sectionsRef.hero}
          // setWhatsapp={setWhatsapp}
        />
        <About {...about} sectionId={aboutId} ref={sectionsRef.about} />
        <Services sectionId={serviceId} ref={sectionsRef.services} />
        <Reviews sectionId={reviewId} ref={sectionsRef.reviews} />
        <Contact sectionId={contactId} ref={sectionsRef.contact} />
        <Footer
          website={website}
          {...footer}
          callback={scrollToSection}
          whatsapp={
            "https://api.whatsapp.com/send/?phone=528117484727&text=Buen+d%C3%ADa%2C+quisiera+agendar+una+cita.&type=phone_number&app_absent=0"
          }
          // whatsapp={whatsapp}
        />
      </>
    </div>
  );
};

export default FrontPage;
