import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './layouts/Layout';
import './App.css';
import './index.css';

const App = () => {

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Layout />
    </Router>
  );
};

export default App;