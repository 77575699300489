import { WhatsAppIcon } from "../../mocks/menu/settings";

const Footer = ({ callback, navigation, social, website, whatsapp }) => {
  return (
    <footer className="bg-white">
      <div className="mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8">
        <nav
          className="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12"
          aria-label="Footer"
        >
          {navigation.map((item) => (
            <div key={item.name} className="pb-6">
              <button
                onClick={() => callback(item.href)}
                className="text-sm leading-6 text-gray-600 hover:text-gray-900"
              >
                {item.name}
              </button>
            </div>
          ))}
        </nav>
        <div className="mt-10 flex justify-center space-x-10">
          {social(website).map((item) => (
            <a
              key={item.name}
              href={item.href}
              target="_blank"
              rel="noreferrer"
              className="text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-6 w-6" aria-hidden="true" />
            </a>
          ))}
          <a
            href={`https://api.whatsapp.com/send/?phone=${whatsapp}&text=Buen+d%C3%ADa%2C+quisiera+agendar+una+cita.&type=phone_number&app_absent=0`}
            target="_blank"
            rel="noreferrer"
            className="text-gray-400 hover:text-gray-500"
          >
            <span className="sr-only">whatsapp</span>
            <WhatsAppIcon className="h-6 w-6 -ml-8" aria-hidden="true" />
          </a>
        </div>
        <p className="mt-10 text-center text-xs leading-5 text-gray-500">
          &copy; {new Date().getFullYear()} Powered by{" "}
          <a href="https://medflix.mx" className="font-bold">
            medflix
          </a>
          . Todos los derechos reservados.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
